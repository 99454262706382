import React, { useState } from 'react'
import styled from 'styled-components'

import { Roster } from '../styled/Roster'
import { H1 } from '../styled/Heading'
import { TabsList, TabContent, TabTextList, TabText } from '../styled/Tabs'
import Contain from '../styled/Contain'

import Course from './Course'

import { getDate } from '../utils/helpers'

const Courses = ({ currentCourses, futureCourses }) => {
  // Set initial courses to current courses
  const [courses, setCourses] = useState(currentCourses)
  const [current, setCurrent] = useState(true)
  const [future, setFuture] = useState(false)

  const order = {
    Beginner: 1,
    Intermediate: 2,
    Advanced: 3,
    AdvancedXcombi: 4,
    default: 5,
  }

  const sortLevels = courses.sort(
    (a, b) =>
      (order[a.node.data.Cursussen[0].data.Niveau] || order.default) -
      (order[b.node.data.Cursussen[0].data.Niveau] || order.default)
  )

  const groupedCourses = sortLevels.reduce((accumulator, currentValue) => {
    let course

    if (currentValue.node.data.Cursussen === null) {
      course = []
    } else {
      course = currentValue.node.data.Cursussen.map(
        (cursus) => cursus.data.Niveau || null
      )
    }

    // If there is no course yet create new array
    if (!accumulator[course]) {
      accumulator[course] = []
    }

    // Push course into corresponding array
    accumulator[course].push(currentValue)

    return accumulator
  }, [])

  const handleCurrentCourses = () => {
    if (courses === currentCourses) {
      return null
    }
    setCourses(currentCourses)
    setFuture(false)
    setCurrent(true)
  }

  const handleFutureCourses = () => {
    if (courses === futureCourses) {
      return null
    }
    setCourses(futureCourses)
    setCurrent(false)
    setFuture(true)
  }

  // Set initial tab to first item in array
  const [activeTab, setActiveTab] = useState(0)

  const handleActiveTab = (index) => {
    setActiveTab(index)
  }

  const getPeriod = (start, end) => {
    const startDate = new Date(start)
    const startMonth = getDate(startDate, { month: true })
    const endDate = new Date(end)
    const endMonth = endDate.toLocaleString('nl-NL', { month: 'long' })
    const period = `${startMonth} - ${endMonth}`
    return period
  }

  function handleDaySort(obj1, obj2) {
    console.log(obj1.node.data)
    // Compare by 'Dagen'
    const dayComparison = obj1.node.data.Dagen.localeCompare(
      obj2.node.data.Dagen
    )

    // If 'Dagen' is NOT the same
    if (dayComparison !== 0) {
      return dayComparison
    }

    // If 'Dagen' is the same, compare by 'Starttijd'
    return obj1.node.data.Starttijd.localeCompare(obj2.node.data.Starttijd)
  }

  return (
    <CoursesWrapper>
      <Contain size="medium">
        <H1 as="h2">Lesrooster</H1>
      </Contain>
      <Contain size="small">
        <TabTextList>
          <li>
            <TabText
              type="button"
              disabled={current}
              onClick={() => handleCurrentCourses()}
            >
              {getPeriod(
                currentCourses[0].node.data.Startdatum,
                currentCourses[0].node.data.Einddatum
              )}
            </TabText>
          </li>

          {getPeriod(futureCourses[0].node.data.Startdatum) !==
            getPeriod(currentCourses[0].node.data.Startdatum) && (
            <li>
              <TabText
                type="button"
                disabled={future}
                onClick={() => handleFutureCourses()}
              >
                {getPeriod(
                  futureCourses[0].node.data.Startdatum,
                  futureCourses[0].node.data.Einddatum
                )}
              </TabText>
            </li>
          )}
        </TabTextList>
        <TabsList role="tablist" style={{ display: 'flex' }}>
          {Object.keys(groupedCourses).map((label, index) => (
            <li activetab={activeTab} key={`${label}-${index}`}>
              <button
                onClick={() => handleActiveTab(index)}
                type="button"
                role="tab"
                id={`tab-${label}-${index}`}
                aria-controls={`${label}-${index}`}
                aria-selected={activeTab === index}
                disabled={activeTab === index}
              >
                {label}
              </button>
            </li>
          ))}
          <li activetab={100} key={`weekoverzicht-${100}`}>
            <button
              onClick={() => handleActiveTab(100)}
              type="button"
              role="tab"
              id={`tab-weekoverzicht-${100}`}
              aria-controls={`weekoverzicht-${100}`}
              aria-selected={activeTab === 100}
              disabled={activeTab === 100}
            >
              weekoverzicht
            </button>
          </li>
        </TabsList>

        {activeTab !== 100 ? (
          Object.keys(groupedCourses).map((groupedCourse, index) => (
            <TabContent
              hidden={activeTab !== index}
              key={`${groupedCourse}-${index}`}
              id={`${groupedCourse}-${index}`}
            >
              <Roster key={groupedCourse}>
                {groupedCourses[groupedCourse]
                  .sort(handleDaySort)
                  .map((course, i) => (
                    <Course key={i} course={course} />
                  ))}
              </Roster>
            </TabContent>
          ))
        ) : (
          <TabContent
            hidden={activeTab !== 100}
            key={`weekoverzicht-${100}`}
            id={`weekoverzicht-${100}`}
          >
            <Roster key="weekoverzicht">
              {courses.sort(handleDaySort).map((course, i) => (
                <Course key={i} course={course} />
              ))}
            </Roster>
          </TabContent>
        )}
      </Contain>
    </CoursesWrapper>
  )
}

export default Courses

const CoursesWrapper = styled.section`
  margin-top: 40px;

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    margin-top: 60px;
  }
`
