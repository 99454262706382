import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Contain from '../styled/Contain'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Courses from '../components/Courses'
import Banner from '../components/Banner'
import FAQ from '../components/Faq'

import bannerImage from '../images/lespartner.jpg'

export default function Lesrooster({ data }) {
  // 1. Get all current courses (ascending)
  const currentCourses = data.current.edges

  // 2. Get all future courses (ascending)
  const futureCourses = data.future.edges

  const { frontmatter, html } = data.markdownRemark
  const { title } = frontmatter

  return (
    <Layout>
      <Contain>
        <Courses
          currentCourses={currentCourses}
          futureCourses={futureCourses}
        />
        <Banner
          title={title}
          html={html}
          buttonLabel="Bekijk agenda"
          image={bannerImage}
        />
        <FAQ
          currentPage="Lesrooster"
          intro="Dit zijn de meestgestelde vragen over het rooster en de tarieven, hier kun je het juiste antwoord vinden."
        />
      </Contain>
    </Layout>
  )
}

export const Head = () => <SEO title="Lesrooster" />

export const lesroosterQuery = graphql`
  query {
    current: allAirtable(
      filter: { table: { eq: "Huidig" } }
      sort: { fields: data___Startdatum, order: ASC }
    ) {
      edges {
        node {
          data {
            Starttijd
            Eindtijd
            Dagen
            Startdatum
            Einddatum
            Cursussen {
              data {
                Naam
                Niveau
              }
            }
            Docenten {
              data {
                Voornaam
              }
            }
          }
        }
      }
    }
    future: allAirtable(
      filter: { table: { eq: "Toekomst" } }
      sort: { fields: data___Startdatum, order: ASC }
    ) {
      edges {
        node {
          data {
            Starttijd
            Eindtijd
            Dagen
            Startdatum
            Einddatum
            Cursussen {
              data {
                Naam
                Niveau
              }
            }
            Docenten {
              data {
                Voornaam
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: "signup" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

Lesrooster.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}
