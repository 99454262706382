import React from 'react'
import styled from 'styled-components'
import { Calendar } from 'react-feather'
import couple from '../images/couple.svg'
import individual from '../images/individual.svg'

import {
  RosterItem,
  RosterSidebar,
  RosterContent,
  RosterInfo,
  RosterList,
} from '../styled/Roster'
import { H3, H4 } from '../styled/Heading'

const Course = ({ course }) => {
  const { Startdatum, Starttijd, Eindtijd, Cursussen, Docenten } =
    course.node.data

  return (
    <RosterItem small>
      <RosterSidebar>
        <H4 sansSerif>
          {new Date(Startdatum).toLocaleDateString('nl-NL', {
            weekday: 'short',
            timeZone: 'Europe/Amsterdam',
          })}
        </H4>
        <ul>
          <li>
            <time dateTime={Starttijd}>{Starttijd}</time>
          </li>
          <li>
            <time dateTime={Eindtijd}>{Eindtijd}</time>
          </li>
        </ul>
      </RosterSidebar>
      <RosterContent>
        {Cursussen && <H3 as="h5">{Cursussen[0].data.Naam}</H3>}
        <RosterInfo>
          <RosterList>
            <li>
              <CourseTeachers>
                {Docenten.map(
                  (teacher, index) =>
                    teacher && <li key={index}>{teacher.data.Voornaam}</li>
                ).reverse()}
              </CourseTeachers>
            </li>
            {Startdatum && (
              <li>
                <Calendar size={16} />
                <span>Start</span>
                <time
                  dateTime={new Date(Startdatum).toLocaleDateString('nl-NL', {
                    timeZone: 'Europe/Amsterdam',
                  })}
                >
                  {new Date(Startdatum).toLocaleDateString('nl-NL', {
                    day: 'numeric',
                    month: 'long',
                    timeZone: 'Europe/Amsterdam',
                  })}
                </time>
              </li>
            )}
          </RosterList>
        </RosterInfo>
      </RosterContent>
    </RosterItem>
  )
}

export default Course

const CourseTeachers = styled.ul`
  > li {
    display: inline;
    line-height: 2;

    &:nth-child(2) {
      &::after {
        content: '\n';
        display: block;
      }
    }

    &:first-child,
    &:nth-child(3n) {
      &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background-image: url(${couple});
        background-repeat: no-repeat;
        background-position: 0 2px;
      }

      &::after {
        content: '&';
        margin: 0 4px;
      }

      &:last-child {
        &::before {
          background-image: url(${individual});
        }
        &::after {
          display: none;
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.medium}) {
    > li {
      line-height: 1.5;

      &:nth-child(2) {
        &::after {
          content: '';
          display: none;
        }
      }

      &:nth-child(3n) {
        margin-left: 16px;
      }
    }
  }
`
