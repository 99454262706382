import styled from 'styled-components'

import pattern from '../images/pattern-primary.svg'

export const TabsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  li {
    margin-top: 4px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    button {
      padding: 8px 16px;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      border: ${props => props.theme.borderWidth} solid
        ${props => props.theme.colors.text};
      outline: none;
      background-color: ${props => props.theme.colors.background};
      color: ${props => props.theme.colors.text};
      font-size: ${props => props.theme.fontSizes.small};
      font-weight: ${props => props.theme.fontWeights.button};
      letter-spacing: ${props => props.theme.letterSpacings.button};
      line-height: ${props => props.theme.lineHeights.button};
      text-transform: uppercase;
      white-space: nowrap;
      cursor: pointer;

      &:disabled {
        background-color: ${props => props.theme.colors.text};
        background-image: url(${pattern});
        color: ${props => props.theme.colors.background};
        cursor: default;
        pointer-events: none;
      }

      @media (hover: hover) {
        &:hover {
          background-color: ${props => props.theme.colors.text};
          background-image: url(${pattern});
          color: ${props => props.theme.colors.background};
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    margin-top: 32px;

    li {
      margin-top: 8px;
      margin-right: 8px;

      button {
        padding: 16px 24px;
        font-size: ${props => props.theme.fontSizes.button};
      }
    }
  }
`

export const TabContent = styled.div`
  margin-top: 48px;

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    margin-top: 64px;
  }
`

export const TabTextList = styled.ul`
  display: inline-flex;
  margin-top: 40px;

  li {
    &::after {
      content: '';
      margin-right: 4px;
      border-right: 2px solid ${props => props.theme.colors.secondary};
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    li {
      margin-right: 8px;

      &::after {
        margin-left: 8px;
      }
    }
  }
`

export const TabText = styled.button`
  transition: opacity 0.2s ease-in;
  border: none;
  outline: none;
  opacity: 0.5;
  background-color: transparent;
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.button};
  cursor: pointer;

  &:disabled {
    opacity: 1;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    font-size: ${props => props.theme.fontSizes.body};
  }
`
