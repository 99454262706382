import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { ArrowRight } from 'react-feather'
import pattern from '../images/pattern-secondary.svg'

export const Button = ({ buttonLabel, isDisabled, type, url }) => (
  <ButtonWrapper to={url} type={type} disabled={isDisabled}>
    {buttonLabel}
    <ArrowRight size={16} />
  </ButtonWrapper>
)

Button.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export const ButtonLink = ({ buttonLabel, isDisabled, url }) => (
  <ButtonWrapperLink to={url} disabled={isDisabled}>
    {buttonLabel}
    <ArrowRight size={16} />
  </ButtonWrapperLink>
)

ButtonLink.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  url: PropTypes.string,
}

// Same styling as ButtonWrapper link, keep in sync!
const ButtonWrapper = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 20px 40px;
  border: none;
  background-color: ${(props) => props.theme.colors.secondary};
  background-image: url(${pattern});
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.fontSizes.button};
  font-weight: ${(props) => props.theme.fontWeights.button};
  letter-spacing: ${(props) => props.theme.letterSpacings.button};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-left: 16px;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    background-color: #edae00;

    svg {
      transform: translateX(10px);
    }
  }
`

// Same styling as ButtonWrapper link, keep in sync!
const ButtonWrapperLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 20px 40px;
  transition: background-color 0.2s ease-in-out;
  border: none;
  background-color: ${(props) => props.theme.colors.secondary};
  background-image: url(${pattern});
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.fontSizes.button};
  font-weight: ${(props) => props.theme.fontWeights.button};
  letter-spacing: ${(props) => props.theme.letterSpacings.button};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-left: 16px;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    background-color: #edae00;

    svg {
      transform: translateX(10px);
    }
  }
`
