import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Minus, Plus } from 'react-feather'

import { H4 } from '../styled/Heading'

const Question = ({ faq }) => {
  const { Vraag, Antwoord } = faq.node.data
  const [active, setActive] = useState(false)

  const handleActive = () => {
    setActive(!active)
  }

  return (
    <QuestionWrapper>
      <H4>
        <button onClick={handleActive} type="button" aria-expanded={active}>
          {Vraag}
          {active ? <Minus size={16} /> : <Plus size={16} />}
        </button>
      </H4>
      {active && (
        <div
          dangerouslySetInnerHTML={{
            __html: Antwoord.childMarkdownRemark.html,
          }}
        />
      )}
    </QuestionWrapper>
  )
}

Question.propTypes = {
  faq: PropTypes.shape({
    node: PropTypes.shape({
      data: PropTypes.shape({
        Vraag: PropTypes.string.isRequired,
        Antwoord: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Question

const QuestionWrapper = styled.li`
  margin-top: 32px;
  padding-bottom: 32px;
  border-bottom: ${props => props.theme.borderWidth} solid
    ${props => props.theme.colors.secondary};

  &:first-of-type {
    margin-top: 64px;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.heading};
    font-size: ${props => props.theme.fontSizes.h4};
    font-weight: ${props => props.theme.fontWeights.heading};
    line-height: ${props => props.theme.lineHeights.heading};
    text-align: left;
    cursor: pointer;

    svg {
      flex: 0 0 16px;
      margin-top: 8px;
      margin-left: 32px;
    }
  }

  > div {
    margin-top: 24px;

    ul + *,
    ol + *,
    p + * {
      margin-top: 16px;
    }

    ul {
      li {
        display: list-item;
        position: relative;
        padding-left: 40px;

        &::before {
          content: '—';
          position: absolute;
          left: 0;
          color: ${props => props.theme.colors.secondary};
          font-weight: ${props => props.theme.fontWeights.heading};
        }
      }
    }
  }
`
