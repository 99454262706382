import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonLink } from './Button'
import { H2 } from '../styled/Heading'
import Contain from '../styled/Contain'
import pattern from '../images/pattern-secondary.svg'

const Banner = (props) => {
  const { title, html, buttonLabel, image, reverse } = props

  return (
    <BannerWrapper reverse={reverse}>
      <BannerImage reverse={reverse}>
        <img src={image} alt={title} />
      </BannerImage>
      <BannerContent reverse={reverse}>
        <H2>{title}</H2>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        {buttonLabel && <ButtonLink url="/agenda" buttonLabel={buttonLabel} />}
      </BannerContent>
    </BannerWrapper>
  )
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  reverse: PropTypes.bool,
}

export default Banner

const BannerWrapper = styled(Contain)`
  margin-top: 80px;

  p {
    margin-top: 40px;
  }

  a {
    margin-top: 56px;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    display: flex;
    margin-top: 160px;
  }
`

const BannerContent = styled.div`
  margin-top: 32px;

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    order: ${(props) => (props.reverse ? '2' : '1')};
    margin-top: 0;
    padding-right: ${(props) => (props.reverse ? '0' : '64px')};
    padding-left: ${(props) => (props.reverse ? '64px' : '0')};
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.wide}) {
    padding-right: ${(props) => (props.reverse ? '0' : '96px')};
    padding-left: ${(props) => (props.reverse ? '96px' : '0')};
  }
`

const BannerImage = styled.div`
  position: relative;

  &::before {
    width: 150px;
    height: 150px;
    content: '';
    position: absolute;
    z-index: 1;
    right: ${(props) => (props.reverse ? 'auto' : '0')};
    bottom: 0;
    left: ${(props) => (props.reverse ? '0' : 'auto')};
    transform: ${(props) =>
      props.reverse ? 'translate3d(-50%, 50%, 0)' : 'translate3d(50%, 50%, 0)'};
    background-color: ${(props) =>
      props.reverse
        ? 'rgba(109, 203, 206, 0.2)'
        : props.theme.colors.secondary};
    mix-blend-mode: multiply;
    background-image: url(${pattern});
  }

  img {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    flex: 0 0 50%;
    align-self: flex-start;
    order: ${(props) => (props.reverse ? '1' : '2')};

    &::before {
      width: 300px;
      height: 300px;
    }
  }
`
