export const formatPrice = (price) => {
  if (price === null) {
    return
  }

  if (price === 0) {
    return 'Gratis'
  }

  return price.toLocaleString('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
  })
}

export const getDate = (
  date,
  {
    minute = false,
    hour = false,
    day = false,
    weekday = false,
    month = false,
    year = false,
  } = {}
) =>
  date.toLocaleDateString('nl-NL', {
    minute: minute ? '2-digit' : undefined,
    hour: hour ? '2-digit' : undefined,
    day: day ? 'numeric' : undefined,
    weekday: weekday ? 'short' : undefined,
    month: month ? 'long' : undefined,
    year: year ? 'numeric' : undefined,
  })

export const formatTimeRange = (start, end) =>
  `${new Date(start).toLocaleTimeString('nl-NL', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Europe/Amsterdam',
  })} - ${new Date(end).toLocaleTimeString('nl-Nl', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Europe/Amsterdam',
  })}`

export const formatDate = (date) =>
  `${new Date(date).toLocaleDateString('nl-NL', {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    timeZone: 'Europe/Amsterdam',
  })}`

// Encode Netlify forms
export const encode = (dataSet) =>
  Object.keys(dataSet)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(dataSet[key])}`
    )
    .join('&')
