import styled from 'styled-components'

const Base = styled.p`
  font-family: ${props => props.theme.fonts.body};
  font-weight: ${props => props.theme.fontWeights.body};
  line-height: ${props => props.theme.lineHeights.body};
`
export const Paragraph = styled(Base)`
  font-size: ${props => props.theme.fontSizes.body};
  letter-spacing: ${props => props.theme.letterSpacings.body};
`

export const Intro = styled(Base)`
  margin-top: 16px;
  font-size: ${props => props.theme.fontSizes.intro};
  letter-spacing: ${props => props.theme.letterSpacings.intro};

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 40px;
  }
`

export const Small = styled(Base)`
  font-size: ${props => props.theme.fontSizes.button};
  letter-spacing: ${props => props.theme.letterSpacings.button};
`
