import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { H2 } from '../styled/Heading'
import { Paragraph } from '../styled/Text'
import Contain from '../styled/Contain'

import Question from './Question'

const FAQ = ({ currentPage, intro }) => {
  const data = useStaticQuery(graphql`
    query FAQuery {
      allAirtable(
        filter: { table: { eq: "FAQ" } }
        sort: { order: ASC, fields: data___Number }
      ) {
        edges {
          node {
            table
            data {
              Antwoord {
                childMarkdownRemark {
                  html
                }
              }
              Vraag
              Zichtbaar_op_pagina
              Number
            }
          }
        }
      }
    }
  `)

  // Get all FAQ's
  const faqs = data.allAirtable.edges

  // Filter FAQ's for current page
  const filterdFaqs = faqs.filter(faq =>
    faq.node.data.Zichtbaar_op_pagina.includes(currentPage)
  )

  return (
    <>
      {!filterdFaqs.length > 0 ? null : (
        <FaqWrapper size="small">
          <H2 as="h3" marginTop>
            {currentPage === 'English'
              ? `Frequently Asked Questions`
              : `Meestgestelde vragen`}
          </H2>
          <Paragraph>{intro}</Paragraph>
          <ul>
            {filterdFaqs.map((faq, index) => (
              <Question faq={faq} key={index} />
            ))}
          </ul>
        </FaqWrapper>
      )}
    </>
  )
}

FAQ.propTypes = {
  currentPage: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
}

export default FAQ

const FaqWrapper = styled(Contain)`
  margin-top: 80px;

  p {
    margin-top: 16px;
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 160px;

    p {
      margin-top: 40px;
    }
  }
`
