import styled from 'styled-components'

import Contain from './Contain'
import { H4 } from './Heading'

export const Roster = styled.div`
  display: grid;
  grid-row-gap: 40px;
`

export const RosterText = styled.div`
  position: relative;
  height: ${props => (props.active ? 'auto' : '96px')};
  margin-top: 24px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    z-index: ${props => (props.active ? '0' : '1')};
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    background-image: ${props =>
      props.active
        ? ''
        : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)'};
    pointer-events: ${props => (props.active ? 'none' : 'all')};
  }

  ul + *,
  ol + *,
  p + * {
    margin-top: 16px;
  }

  a {
    position: relative;
    z-index: 1;
  }

  ul {
    li {
      display: list-item;
      position: relative;
      padding-left: 40px;

      &::before {
        content: '—';
        position: absolute;
        left: 0;
        color: ${props => props.theme.colors.secondary};
        font-weight: ${props => props.theme.fontWeights.heading};
      }
    }
  }
`

export const RosterItem = styled(Contain)`
  display: grid;
  grid-template-areas:
    'sidebar'
    'content';
  grid-template-rows: auto;
  padding-bottom: 24px;
  cursor: ${props => (props.hasClick ? 'pointer' : 'default')};
  overflow: hidden;

  &:hover {
    ${RosterText}::after {
      opacity: 0;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    grid-column-gap: 48px;
    grid-template-areas: 'sidebar content';
    grid-template-columns: 160px auto;
    padding-bottom: 0;
  }
`

export const RosterSidebar = styled.div`
  grid-area: sidebar;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: ${props => props.theme.borderWidth} solid
    ${props => props.theme.colors.secondary};

  ${H4} {
    display: inline-block;
    margin-right: 24px;
  }

  ul {
    display: inline-flex;
    align-items: flex-end;
    line-height: 1;

    li {
      margin-right: 4px;
      font-size: ${props => props.theme.fontSizes.h5};
      font-weight: ${props => props.theme.fontWeights.heading};

      &:last-child {
        margin-right: 0;
        font-size: ${props => props.theme.fontSizes.body};
        font-weight: ${props => props.theme.fontWeights.body};

        &::before {
          content: '-';
          margin-right: 4px;
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 0;
    border-right: ${props => props.theme.borderWidth} solid
      ${props => props.theme.colors.secondary};
    border-bottom: 0;

    ul {
      margin-top: 16px;
    }
  }
`

export const RosterContent = styled.div`
  grid-area: content;
`

export const RosterInfo = styled.div`
  margin-top: 16px;
`

export const RosterList = styled.ul`
  &:empty {
    display: none;
  }

  > li {
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    time {
      font-size: inherit;
      font-weight: inherit;
    }
    span {
      display: inline-block;
      margin-right: 6px;
    }

    svg {
      margin-right: 8px;
    }
  }
`
